import React from 'react';
import {useNavigate} from 'react-router-dom';
import homepageLogo from '../images/animal-free-logo4.png';
import searchGlass from '../images/wsearch.png';
import '../Home.css';

export default function Home(props) {
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault();
        let querySearch = e.target.query.value;
        let querySearchCleaned = querySearch.replaceAll(' ', '+');
        let key = 'JLLUfmVvpnoctQb4R9gj4ONvcxogqbjkhamaDbpc'

        let queryString = `https://api.nal.usda.gov/fdc/v1/foods/search?query=${querySearchCleaned}&api_key=${key}`;

        fetch(queryString)
        .then(res => res.json())
        .then(data => {
            props.getSearchResults([querySearch, data]);
            navigate('/results');

        })
    }
    function FocusOnInput()
    {
         document.getElementById("query").focus();
    }

    //function onload() {
    //    document.getElementById("logo").remove();
    //}
    
      
  return (
    <>
        <div className='container' onload="FocusOnInput()">
            <div className='pt-5 mt-5'>
            <img id="homepageLogo" src={homepageLogo} alt='Animal Free Logo' className='d-block mx-auto mb-5' style={{width: '80%', padding: '0'}}/>
            <div className='d-flex justify-content-center'>
                <form onSubmit={handleSubmit} className='w-800 px-3 py-3'>
                    <div className="input-group mb-3 h-100 d-flex justify-content-center">
                        <input type="text" className="ps-2 bg-light fs-2 rounded-start border border-3 border-dark" returnKeyType="search" placeholder="Type in a grocery" aria-describedby="button-addon2" autoComplete='off' id='query' required style={{width: '80%', padding: '0'}}/>
                        <button type="submit" className="btn btn-dark fs-3 rounded-end" style={{width: '20%', padding: '0'}}><img src={searchGlass} style={{width: '70%', padding: '0'}}/></button>
                    </div>
                </form>
            </div>
            </div>
        </div>
    </>
    
  )
}
